import React, { Suspense, lazy } from 'react';
import { render } from 'react-dom';
const AppWithStore = React.lazy(() => import('./components/AppWithStore'));
import 'regenerator-runtime/runtime';
import './i18n';
import { LinearProgress } from '@material-ui/core';

render(
	<Suspense fallback={<LinearProgress />}>
		<AppWithStore />
	</Suspense>,
	document.getElementById('root'),
);
